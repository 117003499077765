<template>
 <el-scrollbar style="height:100%">
    <div style="padding: 30px;">
        <p style="font-size: 20px;font-weight: bold;">标题：{{title}}</p>
        <div class="content" v-html="content"></div>
    </div>
    </el-scrollbar>
</template>


<script>

export default {
    data(){
        return{
             title:this.$route.query.title,
             content:this.$route.query.content
        }
    },
    created(){
        console.log(this.$route.query,'888')
    },
    methods:{
        
       
    }
    
    
}
</script>
<style scoped lang="scss">
    .content ::v-deep img{
        width: 100%;
    }
    .content {
        margin-top: 20px;

        ::v-deep p {
            img {
                max-width: 500px;
            }
        }
    }
</style>
